export type ApiErrorDetails = {
    error: string;
    code: number;
    message: string;
};
export interface ApiErrorResponse {
    status: number;
    data: {
        details: ApiErrorDetails;
        error: string;
    };
}

export type ApiErrorInputsData = {
    [key: string]: string[];
};

export interface ApiErrorInputsResponse {
    data: {
        details: ApiErrorInputsData;
        error: string;
    };
    status: number;
}

export interface CodeListI {
    400: string;
    404: string;
    10100: string;
    10101: string;
    10102: string;
    10103: string;
    10104: string;
    10105: string;
    10106: string;
    10107: string;
    10108: string;
    101109: string;
    101110: string;
    101111: string;
    101112: string;
    101113: string;
    101114: string;
    101115: string;
    101116: string;
    101117: string;
}

export const codeList: CodeListI = {
    400: 'Неверный запрос',
    404: 'Не найдено',
    10100: 'Неправильный логин или пароль',
    10101: 'Email уже существует',
    10102: 'Не удалось отправить email',
    10103: 'Недействительный или истекший токен',
    10104: 'Пользователь уже подтвержден',
    10105: 'Токен истек',
    10106: 'Слишком много запросов',
    10107: 'Приглашение уже существует',
    10108: 'Ошибка удаления',
    101109: 'Пользователь уже существует',
    101110: 'Недействительный токен',
    101111: 'Неверный шаг',
    101112: 'Неверный код',
    101113: 'СМС не отправлено',
    101114: 'Несоответствие ID оборудования',
    101115: 'Лицензия истекла',
    101116: 'Доступ запрещен',
    101117: 'Капча недействительна',
};
