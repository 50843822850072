import s from './Step3.module.scss';
import { Input } from '@/shared/ui/Input/Input';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { Step3Query, useStep3Mutation } from '../../api/createUsualAccountApi';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import { Step3Data } from '../../model/types/step3';
import { step3Schema } from '../../lib/validationSchema/Step3Schema';
import { Button } from '@/shared/ui/Button/Button';
import { InputPassword } from '@/shared/ui/InputPassword/InputPassword';
import {
    resetRegistrationData,
    setRegistrationData,
} from '@/features/CreateUsualAccountForm';
import {
    prevStep,
    resetCreateUsualAccountStepperData,
} from '@/entities/CreateUsualAccountStepper';
import {
    setErrorStatusModal,
    setSuccessStatusModal,
} from '@/entities/StatusModal';
import { useFormError } from '@/shared/lib/hooks/useFormError';
import { useScreenDetector } from '@/shared/lib/hooks/useScreenDetector';
import { PasswordRequirementsList } from '@/shared/ui/PasswordRequirementsList/PasswordRequirementsList';

interface Step3Props {
    onClose: () => void;
}

export const Step3 = (props: Step3Props) => {
    const dispatch = useAppDispatch();
    const { onClose } = props;
    const { isMobile } = useScreenDetector();

    const [triggerStep3, { isLoading, isSuccess, error }] = useStep3Mutation();

    const {
        registrationToken,
        data: { firstName, lastName, surname },
    } = useAppSelector((state) => state.createUsualAccount);

    const {
        control,
        handleSubmit,
        formState: { errors, isSubmitted, touchedFields },
        setError,
    } = useForm<Step3Data>({
        mode: 'onTouched',
        resolver: yupResolver(step3Schema),
        criteriaMode: 'all',
        defaultValues: {
            login: '',
            password: '',
            confirmPassword: '',
        },
    });

    const onSubmit: SubmitHandler<Step3Data> = (data) => {
        triggerStep3({
            registrationToken,
            login: data.login,
            password: data.password,
        }).then(() => {
            dispatch(
                setRegistrationData({
                    login: data.login,
                }),
            );
        });
    };

    useEffect(() => {
        if (isSuccess) {
            dispatch(
                setSuccessStatusModal({
                    name: surname
                        ? lastName + ' ' + firstName + ' ' + surname
                        : lastName + ' ' + firstName,
                    entity: 'usual',
                }),
            );
            dispatch(resetCreateUsualAccountStepperData());
            dispatch(resetRegistrationData());
            onClose();
        }
    }, [isSuccess]);

    const onError = () => {
        dispatch(
            setErrorStatusModal({
                name: surname
                    ? lastName + ' ' + firstName + ' ' + surname
                    : lastName + ' ' + firstName,
                entity: 'usual',
            }),
        );
    };

    useFormError<Step3Query>(error, setError, onError);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className={s.inputs}>
                <Controller
                    name="login"
                    control={control}
                    render={({ field: { value, onChange, onBlur, name } }) => (
                        <Input
                            value={value}
                            onChange={onChange}
                            label={'Логин*'}
                            placeholder={'username123'}
                            error={errors.login?.message}
                            isSubmitted={isSubmitted}
                            onBlur={onBlur}
                            name={name}
                        />
                    )}
                />

                <Controller
                    name="password"
                    control={control}
                    render={({ field: { value, onChange, onBlur, name } }) => (
                        <InputPassword
                            value={value}
                            onChange={onChange}
                            label={'Пароль*'}
                            placeholder={'Введите пароль'}
                            error={errors.password?.message}
                            onBlur={onBlur}
                            name={name}
                        />
                    )}
                />
                <Controller
                    name="confirmPassword"
                    control={control}
                    render={({ field: { value, onChange, onBlur, name } }) => (
                        <InputPassword
                            value={value}
                            onChange={onChange}
                            label={'Повторите пароль*'}
                            placeholder={'Введите пароль'}
                            error={errors.confirmPassword?.message}
                            onBlur={onBlur}
                            name={name}
                        />
                    )}
                />
            </div>
            <PasswordRequirementsList
                error={errors.password}
                isTouched={touchedFields.password}
            />
            <div className={s.actions}>
                <Button
                    onClick={() => dispatch(prevStep())}
                    variant={'outline'}
                    className={s.prev}
                    size={isMobile ? 's' : 'm'}
                >
                    Назад
                </Button>
                <Button
                    type={'submit'}
                    className={s.next}
                    disabled={isLoading}
                    size={isMobile ? 's' : 'm'}
                    onClick={handleSubmit(onSubmit)}
                >
                    Зарегистрироваться
                </Button>
            </div>
        </form>
    );
};
