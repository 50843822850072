import { Navigate, RouteObject } from 'react-router-dom';
import { NotFound } from '@/pages/NotFound';
import { MainLayout } from '@/pages/MainLayout';
import { UsualTable } from '@/widgets/UsualTable';
import { ClubTable } from '@/widgets/ClubTable';

export const routeConfig: RouteObject[] = [
    
    {
        element: <MainLayout />,
        errorElement: <NotFound />,
        children: [
            {
                path: '/usual',
                element: <UsualTable />,
            },
            {
                path: '/club',
                element: <ClubTable />,
            },
            {
                path: '',
                element: <Navigate to={'usual'} replace />,
            },
            {
                path: '/login',
                element: <Navigate to={'/usual'} replace />,
            },
        ],
    },
];
