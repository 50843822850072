import { object, string } from 'yup';
import { getAge } from '@/shared/lib/utils/getAge';

export const step2Schema = object({
    birthDate: string()
        .required('Обязательное поле')
        .matches(/^\d{2}\.\d{2}\.\d{4}$/, 'Неверный формат даты')
        .test(function (value) {
            const age = getAge(value);
            if (age < 0)
                return this.createError({
                    message: `Неверный диапазон`,
                    path: 'birthDate',
                });
            else if (age < 8)
                return this.createError({
                    message: `Вам должно быть больше 8 лет`,
                    path: 'birthDate',
                });
            else if (age > 99)
                return this.createError({
                    message: `Неверный диапазон`,
                    path: 'birthDate',
                });
            else return true;
        }),
    team: string()
        .required('Обязательное поле')
        .matches(
            /^[a-zA-Zа-яА-ЯёЁ0-9-]+$/,
            'Может содержать только кириллические, латинские символы, цифры и символ тире',
        ),
    nickname: string()
        .required('Обязательное поле')
        .matches(
            /^[a-zA-Zа-яА-ЯёЁ0-9-]+$/,
            'Может содержать только кириллические, латинские символы, цифры и символ тире',
        ),
    vkLink: string().matches(
        /^[hH][tT][tT][pP][sS]:\/\/[vV][kK]\.[cC][oO][mM]\/[a-zA-Zа-яА-ЯёЁ0-9_.-]*$/,
        {
            message: 'Ссылка должна содержать “https://vk.com/username“',
            excludeEmptyString: true,
        },
    ),
});
