import { noAuthApi } from '@/shared/api/noAuthApi';

const regionsApi = noAuthApi.injectEndpoints({
    endpoints: (build) => ({
        getRegions: build.query<{ regions: string[] }, string>({
            query: (query) => `/data/regions?q=${query}`,
        }),
    }),
});

export const { useLazyGetRegionsQuery } = regionsApi;
