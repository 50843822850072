import classNames from 'classnames';
import s from './InputTel.module.scss';
import { ChangeEvent, useState } from 'react';
import CheckIcon from '@/shared/assets/icons/check.svg';
import WarningIcon from '@/shared/assets/icons/warning.svg';
import { IMaskInput } from 'react-imask';

interface InputTelProps {
    label: string;
    className?: string;
    placeholder?: string;
    error: string;
    value: string;
    onChange: (e: ChangeEvent<HTMLInputElement> | string) => void;
    disabled?: boolean;
    isSubmitted?: boolean;
    baseAddon?: boolean;
    onBlur?: () => void;
}

export const InputTel = (props: InputTelProps) => {
    const {
        className,
        label,
        placeholder,
        error,
        value = '',
        onChange,
        disabled,
        isSubmitted,
        baseAddon = true,
        onBlur,
    } = props;

    const [focus, setFocus] = useState(false);

    const mods = {
        [s.disabled]: disabled,
        [s.isError]: !!error,
    };

    return (
        <div className={classNames(s.Input, mods, [className])}>
            <label className={s.label} htmlFor={label}>
                {label}
            </label>
            <div className={s.wrapper}>
                <IMaskInput
                    className={classNames(
                        s.input,
                        { [s.baseAddon]: baseAddon },
                        [className],
                    )}
                    onFocus={() => setFocus(true)}
                    onBlur={() => {
                        setFocus(false);
                        onBlur?.();
                    }}
                    value={value}
                    overwrite={true}
                    mask={'+7 000-000-00-00'}
                    autofix={'pad'}
                    onAccept={(value) => onChange(value)}
                    placeholder={placeholder}
                    type={'tel'}
                    disabled={disabled}
                    name={'phone'}
                    id={label}
                    lazy={!focus}
                />
                {baseAddon && isSubmitted && (
                    <div className={s.status}>
                        {error ? (
                            <WarningIcon className={s.warning} />
                        ) : (
                            <CheckIcon className={s.check} />
                        )}
                    </div>
                )}
            </div>
            {error && error.trim().length > 0 && (
                <span className={s.error}>{error}</span>
            )}
        </div>
    );
};
