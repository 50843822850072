import classNames from 'classnames';
import s from './DateInput.module.scss';
import { ChangeEvent, useEffect } from 'react';
import Calendar from '@/shared/assets/icons/calendar.svg';
import { IMaskInput } from 'react-imask';
import { useAlert } from '@/shared/lib/hooks/useAlert';
import { useDispatch } from 'react-redux';
import { alertClose } from '@/entities/Alert';

interface InputProps {
    className?: string;
    placeholder?: string;
    error: string;
    value: string;
    onChange: (e: ChangeEvent<HTMLInputElement> | string) => void;
    disabled?: boolean;
    onBlur?: () => void;
    name?: string;
}

export const DateInput = (props: InputProps) => {
    const {
        className,
        placeholder,
        error,
        value = '',
        onChange,
        disabled,
        onBlur,
        name,
    } = props;

    const onAlert = useAlert();
    const dispatch = useDispatch();
    const mods = {
        [s.disabled]: disabled,
        [s.isError]: !!error,
    };

    useEffect(() => {
        if (!!error) {
            onAlert(error, true);
        } else {
            dispatch(alertClose());
        }
    }, [error]);

    return (
        <div className={classNames(s.Input, mods, [className])}>
            <IMaskInput
                className={classNames(s.input, [className])}
                value={value}
                mask={Date}
                pattern={'d.m`.`Y'}
                overwrite={true}
                autofix={'pad'}
                onAccept={(value) => onChange(value)}
                placeholder={placeholder}
                type={'text'}
                disabled={disabled}
                onBlur={onBlur}
                name={name}
            />
            <Calendar className={s.icon} />
        </div>
    );
};
