import { MutableRefObject, useLayoutEffect, useState } from 'react';

export const useIsOverflow = (
    ref: MutableRefObject<HTMLElement>,
    callback?: (hasOverflow: boolean) => void,
) => {
    const [isOverflowW, setIsOverflowW] = useState(undefined);
    const [isOverflowH, setIsOverflowH] = useState(undefined);

    useLayoutEffect(() => {
        const { current } = ref;

        const trigger = () => {
            const hasOverflowW = current.scrollWidth > current.clientWidth;
            const hasOverflowH = current.scrollHeight > current.clientHeight;

            setIsOverflowW(hasOverflowW);
            setIsOverflowH(hasOverflowH);

            if (callback) callback(hasOverflowW);
        };

        if (current) {
            trigger();
        }
    }, [callback, ref]);

    return { isOverflowW, isOverflowH };
};
