import classNames from 'classnames';
import {
    KeyboardEvent,
    ReactNode,
    useCallback,
    useEffect,
    useLayoutEffect,
    useRef,
    useState,
} from 'react';
import Close from '@/shared/assets/icons/close.svg';
import { IconButton } from '@/shared/ui/IconButton/IconButton';
import { Portal } from '@/shared/ui/Portal/Portal';
import s from './Modal.module.scss';
import { Overlay } from '@/shared/ui/Overlay/Overlay';
import { useScreenDetector } from '@/shared/lib/hooks/useScreenDetector';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
interface ModalProps {
    className?: string;
    onClose: () => void;
    open: boolean;
    onCloseButton?: () => void;
    title?: string;
    titleSize?: 'h1' | 'h2';
    children?: ReactNode;
}

export const Modal = (props: ModalProps) => {
    const {
        className,
        onClose,
        title,
        open,
        children,
        titleSize = 'h2',
        onCloseButton,
    } = props;
    const { isMobile } = useScreenDetector();
    const wrapperRef = useRef(null);
    const contentRef = useRef(null);
    const [isOverflowH, setIsOverflowH] = useState(false);
    const onKeyDown = useCallback(
        (e: KeyboardEvent) => {
            if (e.key === 'Escape') {
                onClose();
            }
        },
        [onClose],
    );

    useLayoutEffect(() => {
        if (!wrapperRef.current && !contentRef.current && !open) return;
        const updateSize = () => {
            setIsOverflowH(
                contentRef.current.scrollHeight + 46 >
                    wrapperRef.current.clientHeight,
            );
        };
        window.addEventListener('resize', updateSize);
        contentRef.current.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, [wrapperRef, contentRef, open]);

    useEffect(() => {
        const modalElement = wrapperRef.current;

        if (open) {
            disableBodyScroll(modalElement);
        } else {
            enableBodyScroll(modalElement);
        }

        return () => enableBodyScroll(modalElement);
    }, [open]);

    const mods = {
        [s.opened]: open,
        [s.scrollable]: isOverflowH,
    };

    return (
        <Portal>
            <>
                <div
                    className={classNames(s.Modal, mods, [className])}
                    onTouchMove={(e) => e.stopPropagation()}
                    ref={wrapperRef}
                    onKeyDown={onKeyDown}
                >
                    <div className={s.content} ref={contentRef}>
                        <IconButton
                            onClick={() => {
                                onClose();
                                onCloseButton?.();
                            }}
                            className={s.close}
                            size={isMobile ? 'xs' : 'm'}
                        >
                            <Close />
                        </IconButton>
                        {title &&
                            (titleSize === 'h1' ? (
                                <h1 className={s.title + ' ' + s.h1}>
                                    {title}
                                </h1>
                            ) : (
                                <h2 className={s.title + ' ' + s.h2}>
                                    {title}
                                </h2>
                            ))}
                        {children}
                    </div>
                </div>
                <Overlay onClose={onClose} />
            </>
        </Portal>
    );
};
