import { object, string } from 'yup';

export const licensePeriodSchema = object({
    licenseStartAt: string()
        .required('Обязательное поле')
        .matches(/^\d{2}\.\d{2}\.\d{4}$/, 'Неверный формат даты')
        .test(function (value) {
            return new Date(this.parent.licenseEndAt) < new Date(value)
                ? this.createError({
                      message: `Неверный диапазон`,
                      path: 'licenseStartAt',
                  })
                : true;
        }),
    licenseEndAt: string()
        .required('Обязательное поле')
        .matches(/^\d{2}\.\d{2}\.\d{4}$/, 'Неверный формат даты')
        .test(function (value) {
            return new Date(this.parent.licenseStartAt) > new Date(value)
                ? this.createError({
                      message: `Неверный диапазон`,
                      path: 'licenseEndAt',
                  })
                : true;
        }),
});
