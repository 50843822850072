import { MutableRefObject, useEffect, useRef } from 'react';

export const useOutsideClick = (open: boolean, onClose: () => void) => {
    const ref = useRef() as MutableRefObject<HTMLDivElement>;

    useEffect(() => {
        const handleClick = (event: MouseEvent) => {
            const { target } = event;
            if (
                target instanceof Node &&
                !ref.current?.contains(target) &&
                open
            ) {
                onClose();
            }
        };

        window.addEventListener('click', handleClick);

        return () => {
            window.removeEventListener('click', handleClick);
        };
    }, [onClose, open, ref]);

    return ref;
};
