import s from './Step1.module.scss';
import { yupResolver } from '@hookform/resolvers/yup';
import { Input } from '@/shared/ui/Input/Input';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Step1Query, useStep1Mutation } from '../../api/createUsualAccountApi';
import {
    setRegistrationData,
    setRegistrationToken,
} from '@/features/CreateUsualAccountForm';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import { useCallback, useEffect } from 'react';
import { useFormError } from '@/shared/lib/hooks/useFormError';
import { InputTel } from '@/shared/ui/InputTel/InputTel';
import { Step1Data } from '@/features/CreateUsualAccountForm/model/types/step1';
import { step1Schema } from '../../lib/validationSchema/Step1Schema';
import { Button } from '@/shared/ui/Button/Button';
import { useScreenDetector } from '@/shared/lib/hooks/useScreenDetector';
import { nextStep } from '@/entities/CreateUsualAccountStepper';
import { phoneNumberFormat } from '@/shared/lib/utils/phoneNumberFormat';
import { RegionSearch, useLazyGetRegionsQuery } from '@/features/RegionSearch';

export const Step1 = () => {
    const dispatch = useAppDispatch();
    const { isMobile } = useScreenDetector();
    const [triggerStep1, { isLoading, isSuccess, error, data }] =
        useStep1Mutation();

    const [triggerRegionsSearch, { isFetching }] = useLazyGetRegionsQuery();
    const {
        data: { phoneNumber, region, firstName, lastName, surname },
    } = useAppSelector((state) => state.createUsualAccount);

    const {
        control,
        handleSubmit,
        formState: { errors, isSubmitted },
        setError,
    } = useForm<Step1Data>({
        mode: 'onTouched',
        resolver: yupResolver(step1Schema),
        defaultValues: {
            firstName: firstName || '',
            lastName: lastName || '',
            surname: surname || '',
            region: region || '',
            phoneNumber: phoneNumber || '',
        },
    });

    const validateRegion = useCallback(async (value: string) => {
        if (value.length > 0 && value !== '-') {
            const result = await triggerRegionsSearch(value);
            if (result?.data?.regions?.[0]?.split('_')[1] !== value) {
                setError('region', {
                    message: 'Выберите регион из списка',
                });
                return false;
            }
        }
        return true;
    }, []);

    const onSubmit: SubmitHandler<Step1Data> = async (data) => {
        if (await validateRegion(data.region)) {
            triggerStep1({
                phoneNumber: phoneNumberFormat(data.phoneNumber),
                fullName: data.surname
                    ? data.lastName + ' ' + data.firstName + ' ' + data.surname
                    : data.lastName + ' ' + data.firstName,
                region: data.region.length > 0 ? data.region : '-',
            }).then(() => {
                dispatch(
                    setRegistrationData({
                        firstName: data.firstName,
                        lastName: data.lastName,
                        surname: data.surname,
                        phoneNumber: data.phoneNumber,
                        region: data.region.length > 0 ? data.region : '-',
                    }),
                );
            });
        }
    };

    useEffect(() => {
        if (isSuccess) {
            dispatch(setRegistrationToken(data.registrationToken));
            dispatch(nextStep());
        }
    }, [isSuccess]);

    useFormError<Step1Query>(error, setError);

    return (
        <div className={s.wrapper}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className={s.inputs}>
                    <Controller
                        name="lastName"
                        control={control}
                        render={({
                            field: { value, onChange, onBlur, name },
                        }) => (
                            <Input
                                value={value}
                                onChange={onChange}
                                label={'Фамилия*'}
                                placeholder={'Введите вашу фамилию'}
                                error={errors.lastName?.message}
                                isSubmitted={isSubmitted}
                                onBlur={onBlur}
                                name={name}
                            />
                        )}
                    />
                    <Controller
                        name="firstName"
                        control={control}
                        render={({
                            field: { value, onChange, onBlur, name },
                        }) => (
                            <Input
                                value={value}
                                onChange={onChange}
                                label={'Имя*'}
                                placeholder={'Введите ваше имя'}
                                error={errors.firstName?.message}
                                isSubmitted={isSubmitted}
                                onBlur={onBlur}
                                name={name}
                            />
                        )}
                    />
                    <Controller
                        name="surname"
                        control={control}
                        render={({
                            field: { value, onChange, onBlur, name },
                        }) => (
                            <Input
                                value={value}
                                onChange={onChange}
                                label={'Отчество'}
                                placeholder={'Введите ваше отчество'}
                                error={errors.surname?.message}
                                isSubmitted={isSubmitted}
                                onBlur={onBlur}
                                name={name}
                            />
                        )}
                    />
                    <Controller
                        name="region"
                        control={control}
                        render={({
                            field: { value, onChange, onBlur, name },
                        }) => (
                            <RegionSearch
                                value={value}
                                onChange={onChange}
                                label={'Регион регистрации'}
                                placeholder={'Введите регион'}
                                error={errors.region?.message}
                                isSubmitted={isSubmitted}
                                onBlur={onBlur}
                                validate={validateRegion}
                                name={name}
                            />
                        )}
                    />
                    <Controller
                        name="phoneNumber"
                        control={control}
                        render={({ field: { value, onChange, onBlur } }) => (
                            <InputTel
                                value={value}
                                onChange={onChange}
                                label={'Номер телефона*'}
                                placeholder={'+7 900 000-00-00'}
                                error={errors.phoneNumber?.message}
                                isSubmitted={isSubmitted}
                                baseAddon={false}
                                onBlur={onBlur}
                            />
                        )}
                    />
                </div>
                <Button
                    type={'submit'}
                    className={s.next}
                    size={isMobile ? 's' : 'm'}
                    disabled={isLoading || isFetching}
                    onClick={handleSubmit(onSubmit)}
                >
                    Далее
                </Button>
            </form>
        </div>
    );
};
