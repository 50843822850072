import classNames from 'classnames';
import s from './Input.module.scss';
import { ChangeEvent, InputHTMLAttributes, ReactNode } from 'react';
import CheckIcon from '@/shared/assets/icons/check.svg';
import WarningIcon from '@/shared/assets/icons/warning.svg';
import InfoIcon from '@/shared/assets/icons/info.svg';
import { TooltipIcon } from '@/shared/ui/TooltipIcon/TooltipIcon';

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    label?: string;
    className?: string;
    placeholder?: string;
    error: string;
    value: string;
    onChange: (e: ChangeEvent<HTMLInputElement> | string) => void;
    disabled?: boolean;
    isSubmitted?: boolean;
    baseAddon?: boolean;
    icon?: ReactNode;
    onFocus?: () => void;
    onBlur?: () => void;
    tooltipValue?: string;
    tooltipClassName?: string;
}

export const Input = (props: InputProps) => {
    const {
        className,
        label,
        placeholder,
        error,
        value = '',
        onChange,
        disabled,
        isSubmitted,
        baseAddon = true,
        icon,
        onFocus,
        onBlur,
        tooltipValue,
        tooltipClassName,
    } = props;

    const mods = {
        [s.disabled]: disabled,
        [s.isError]: !!error,
    };

    return (
        <div className={classNames(s.Input, mods, [className])}>
            {label && (
                <div className={s.labelWrapper}>
                    <label className={s.label} htmlFor={label}>
                        {label}
                    </label>
                    {tooltipValue && (
                        <TooltipIcon
                            value={tooltipValue}
                            className={tooltipClassName}
                        >
                            <InfoIcon />
                        </TooltipIcon>
                    )}
                </div>
            )}
            <div className={s.wrapper}>
                <input
                    className={classNames(
                        s.input,
                        { [s.baseAddon]: baseAddon },
                        [className],
                    )}
                    value={value}
                    onChange={onChange}
                    placeholder={placeholder}
                    type={'text'}
                    id={label}
                    disabled={disabled}
                    onFocus={onFocus}
                    onBlur={onBlur}
                />

                {icon ? (
                    <div className={s.status}>{icon}</div>
                ) : (
                    baseAddon &&
                    isSubmitted && (
                        <div className={s.status}>
                            {error ? (
                                <WarningIcon className={s.warning} />
                            ) : (
                                <CheckIcon className={s.check} />
                            )}
                        </div>
                    )
                )}
            </div>
            {error && error.trim().length > 0 && (
                <span className={s.error}>{error}</span>
            )}
        </div>
    );
};
