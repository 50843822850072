import { object, string } from 'yup';

export const step3Schema = object({
    login: string()
        .required('Обязательное поле')
        .min(2, 'Минимальная длинна логина 2')
        .max(30, 'Максимальная длинна логина 30')
        .matches(/^[A-Za-z0-9]+$/, 'Только латинские символы и цифры'),
    password: string()
        .required('Обязательное поле')
        .test(function (value) {
            const result = value.match(
                /[^A-Za-z~!?@#$%^&*_\-+()\[\]{}><\/\\|"'.,:\d]+/g,
            );
            return result
                ? this.createError({
                      message: `Пароль содержит недопустимые символы (${result})`,
                      path: 'password',
                  })
                : true;
        })
        .min(8, 'Пароль не соответсвует требованиям')
        .max(64, 'Пароль не соответсвует требованиям')
        .matches(/^(?=.*[a-z])(?=.*[A-Z]).+$/, {
            name: 'words',
            message: 'Пароль не соответсвует требованиям',
        })
        .matches(/^(?=.*\d).+$/, {
            name: 'digit',
            message: 'Пароль не соответсвует требованиям',
        })
        .matches(/(?=.*[~!?@#$%^&*_\-+()\[\]{}><\/\\|"'.,:])/, {
            name: 'special',
            message: 'Пароль не соответсвует требованиям',
        }),
    confirmPassword: string()
        .required('Обязательное поле')
        .test(function (value) {
            return this.parent.password !== value
                ? this.createError({
                      message: `Пароли не совпадают`,
                      path: 'confirmPassword',
                  })
                : true;
        }),
});
