import s from './PasswordRequirementsList.module.scss';
import classNames from 'classnames';
import { FieldError } from 'react-hook-form';
import Close from '@/shared/assets/icons/close-s.svg';
import Check from '@/shared/assets/icons/check-s.svg';

interface PasswordRequirementsListProps {
    error: FieldError;
    isTouched: boolean;
}

export const PasswordRequirementsList = (
    props: PasswordRequirementsListProps,
) => {
    const { error, isTouched } = props;

    const listData = [
        {
            type: 'count',
            title: 'Не менее 8 и не более 64 символов',
            isError: (error?.types?.min || error?.types?.max) !== undefined,
            isValid:
                isTouched &&
                (error?.types?.min || error?.types?.max) === undefined,
        },
        {
            type: 'words',
            title: 'Заглавные и строчные латинские буквы',
            isError: error?.types?.words !== undefined,
            isValid: isTouched && error?.types?.words === undefined,
        },
        {
            type: 'digit',
            title: 'Цифры',
            isError: error?.types?.digit !== undefined,
            isValid: isTouched && error?.types?.digit === undefined,
        },
        {
            type: 'special',
            title: `Специальные символы: ~ ! ? @ # $ % ^ & * _ - + ( ) [ ] { } > < / \\\\ | " \' . , :`,
            isError: error?.types?.special !== undefined,
            isValid: isTouched && error?.types?.special === undefined,
        },
    ];

    return (
        <div className={s.info}>
            <span className={s.title}>Пароль должен содержать:</span>
            <ul className={s.list}>
                {listData.map(({ type, isError, title, isValid }) => (
                    <li
                        key={type}
                        className={classNames(s.listItem, {
                            [s.error]: isError,
                            [s.valid]: isValid,
                        })}
                    >
                        {isError && <Close className={s.icon} />}
                        {isValid && <Check className={s.icon} />}
                        {title}
                    </li>
                ))}
            </ul>
        </div>
    );
};
