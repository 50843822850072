import s from './TextCell.module.scss';
import { CellContext } from '@tanstack/react-table';
import { useRef } from 'react';
import { Tooltip } from '@/shared/ui/Tooltip/Tooltip';
import { useIsOverflow } from '@/shared/lib/hooks/useIsOverflow';

interface TextCellProps<T> {
    row: CellContext<T, unknown>;
}

export const TextCell = <T,>(props: TextCellProps<T>) => {
    const { row } = props;
    const wrapperRef = useRef<HTMLDivElement>(null);

    const { isOverflowW } = useIsOverflow(wrapperRef);
    const value = row.getValue();

    return (
        <Tooltip value={String(value)} isOverflow={isOverflowW}>
            <div className={s.TextCell}>
                <div className={s.wrapper} ref={wrapperRef}>
                    <span>{value ? String(value) : '-'}</span>
                </div>
            </div>
        </Tooltip>
    );
};
