import { Modal } from '@/shared/ui/Modal/Modal';
import { useAppSelector } from '@/shared/lib/hooks/redux';
import Success from '@/shared/assets/icons/check_circle.svg';
import Warning from '@/shared/assets/icons/warning.svg';
import s from './StatusModal.module.scss';

interface StatusModalProps {
    onClose: () => void;
    open: boolean;
}

export const StatusModal = (props: StatusModalProps) => {
    const { open, onClose } = props;

    const { status, name, entity } = useAppSelector(
        (state) => state.statusModal,
    );

    const isSuccess = status && status === 'success';

    return (
        <Modal
            onClose={onClose}
            open={open}
            title={isSuccess ? 'Успешно создана' : 'Ошибка создания'}
            titleSize={'h1'}
            className={s.modal}
        >
            <div className={s.wrapper}>
                {isSuccess ? (
                    <>
                        <span className={s.info}>
                            {entity === 'usual' ? 'Простая' : 'Клубная'} учётная
                            запись для 
                            <span className={s.name}>{name}</span> успешно
                            создана
                        </span>
                        <Success className={s.icon} />
                        <span className={s.extraInfo}>
                            Пожалуйста, укажите срок действия лицензии после
                            того, как закроете это окно.
                        </span>
                    </>
                ) : (
                    <>
                        <span className={s.info}>
                            При создании{' '}
                            {entity === 'usual' ? 'простой' : 'клубной'} учётной
                            записи для 
                            <span className={s.name}>{name}</span> произошла
                            ошибка.
                            <br />
                            Повторите попытку позже
                        </span>
                        <Warning className={s.icon + ' ' + s.warning} />
                    </>
                )}
            </div>
        </Modal>
    );
};
