import { object, string } from 'yup';

export const step1Schema = object({
    lastName: string()
        .required('Обязательное поле')
        .min(2, 'Минимальная длина 2 символа')
        .max(32, 'Максимальная длина 32 символа')
        .matches(
            /^[А-Яа-яёЁ\s‘’'-]+$|^[a-zA-Z\s‘’'-]+$/,
            'Только кириллические символы или только латинские символы, тире или апостроф',
        ),
    firstName: string()
        .required('Обязательное поле')
        .min(2, 'Минимальная длина 2 символа')
        .max(16, 'Максимальная длина 16 символов')
        .matches(
            /^[А-Яа-яёЁ\s‘’'-]+$|^[a-zA-Z\s‘’'-]+$/,
            'Только кириллические символы или только латинские символы, тире или апостроф',
        ),
    surname: string()
        .matches(/^.{5,}$/, {
            message: 'Минимальная длина 5 символов',
            excludeEmptyString: true,
        })
        .max(32, 'Максимальная длина 32 символа')
        .matches(/^[А-Яа-яёЁ\s‘’'-]+$|^[a-zA-Z\s‘’'-]+$/, {
            message:
                'Только кириллические символы или только латинские символы, тире или апостроф',
            excludeEmptyString: true,
        }),
    region: string(),
    phoneNumber: string()
        .required('Обязательное поле')
        .matches(
            /^(\+7\s?)?((\(\d{3}\)?)|\d{3})([\s.-]?\d{3})([\s.-]?\d{2})([\s.-]?\d{2})$/,
            'Неверный номер телефона',
        ),
});
