import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type Status = 'success' | 'error' | null;
type Entity = 'club' | 'usual' | null;

interface StatusModalState {
    status: Status;
    name: string;
    entity: Entity;
}

const initialState: StatusModalState = {
    status: null,
    name: '',
    entity: null,
};

export const statusModalSlice = createSlice({
    name: 'statusModalAccount',
    initialState,
    reducers: {
        setSuccessStatusModal: (
            state,
            action: PayloadAction<{ name: string; entity: Entity }>,
        ) => {
            state.status = 'success';
            state.name = action.payload.name;
            state.entity = action.payload.entity;
        },
        setErrorStatusModal: (
            state,
            action: PayloadAction<{ name: string; entity: Entity }>,
        ) => {
            state.status = 'error';
            state.name = action.payload.name;
            state.entity = action.payload.entity;
        },
        onClose: (state) => {
            state.status = null;
            state.name = '';
        },
    },
});

export const { setSuccessStatusModal, setErrorStatusModal, onClose } =
    statusModalSlice.actions;

export const { reducer: statusModalReducer } = statusModalSlice;
