import s from './TooltipIcon.module.scss';
import { ReactNode, useState } from 'react';
import classNames from 'classnames';

interface TooltipProps {
    children: ReactNode;
    value: string;
    className?: string;
}

export const TooltipIcon = (props: TooltipProps) => {
    const { children, value, className } = props;
    const [active, setActive] = useState(false);

    const onMouseEnter = () => {
        setActive(true);
    };
    const onMouseLeave = () => {
        setActive(false);
    };

    return (
        <div
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            className={s.wrapper}
        >
            {children}
            {active && (
                <div className={classNames(s.Tooltip, {}, [className])}>
                    {value}
                </div>
            )}
        </div>
    );
};
