import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface CreateUsualAccountStateData {
    firstName: string;
    lastName: string;
    surname: string;
    region: string;
    phoneNumber: string;
    birthDate: string;
    team: string;
    nickname: string;
    vkLink: string;
}

interface CreateUsualAccountState {
    data: CreateUsualAccountStateData;
    registrationToken: string;
}

const initialState: CreateUsualAccountState = {
    data: {
        phoneNumber: '',
        region: '',
        firstName: '',
        lastName: '',
        surname: '',
        birthDate: '',
        team: '',
        nickname: '',
        vkLink: '',
    },
    registrationToken: null,
};

export const createUsualAccountSlice = createSlice({
    name: 'createUsualAccount',
    initialState,
    reducers: {
        setRegistrationData: (
            state,
            action: PayloadAction<Partial<CreateUsualAccountStateData>>,
        ) => {
            state.data = {
                ...state.data,
                ...action.payload,
            };
        },
        setRegistrationToken: (state, action: PayloadAction<string>) => {
            state.registrationToken = action.payload;
        },

        resetRegistrationData: () => initialState,
    },
});

export const {
    setRegistrationData,
    resetRegistrationData,
    setRegistrationToken,
} = createUsualAccountSlice.actions;

export const { reducer: createUsualAccountReducer } = createUsualAccountSlice;
