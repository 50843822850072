import { configureStore } from '@reduxjs/toolkit';
import { rtkApi } from '@/shared/api/rtkApi';
import { noAuthApi } from '@/shared/api/noAuthApi';
import { appReducer } from '@/app/model/slice/appSlice';
import { usualTableReducer } from '@/widgets/UsualTable';
import { clubTableReducer } from '@/widgets/ClubTable';
import { createUsualAccountStepperReducer } from '@/entities/CreateUsualAccountStepper';
import { createUsualAccountReducer } from '@/features/CreateUsualAccountForm';
import { statusModalReducer } from '@/entities/StatusModal';
import { alertReducer } from '@/entities/Alert';

export const store = configureStore({
    devTools: __MODE__ === 'development',
    reducer: {
        app: appReducer,
        usualTable: usualTableReducer,
        clubTable: clubTableReducer,
        statusModal: statusModalReducer,
        createUsualAccount: createUsualAccountReducer,
        createUsualAccountStepper: createUsualAccountStepperReducer,
        alert: alertReducer,
        [rtkApi.reducerPath]: rtkApi.reducer,
        [noAuthApi.reducerPath]: noAuthApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware()
            .concat(rtkApi.middleware)
            .concat(noAuthApi.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
