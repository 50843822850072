import classNames from 'classnames';
import s from './InputPassword.module.scss';
import { ChangeEvent, useState } from 'react';
import EyeClosedIcon from '@/shared/assets/icons/eye_closed.svg';
import EyeOpenedIcon from '@/shared/assets/icons/eye_opened.svg';

interface InputProps {
    label: string;
    className?: string;
    placeholder?: string;
    error: string;
    value: string;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
    disabled?: boolean;
    onBlur?: () => void;
    name?: string;
}

export const InputPassword = (props: InputProps) => {
    const {
        className,
        label,
        placeholder,
        error,
        value = '',
        onChange,
        disabled,
        onBlur,
        name,
    } = props;

    const mods = {
        [s.disabled]: disabled,
        [s.isError]: !!error,
    };

    const [type, setType] = useState('password');

    const changeType = () => {
        setType((prevState) =>
            prevState === 'password' ? 'text' : 'password',
        );
    };

    return (
        <div className={classNames(s.Input, mods, [className])}>
            <label className={s.label} htmlFor={label}>
                {label}
            </label>
            <div className={s.wrapper}>
                <input
                    className={s.input}
                    value={value}
                    onChange={onChange}
                    placeholder={placeholder}
                    type={type}
                    id={label}
                    disabled={disabled}
                    onBlur={onBlur}
                    name={name}
                />

                {
                    <button
                        type={'button'}
                        className={s.status}
                        onClick={changeType}
                    >
                        {type === 'password' ? (
                            <EyeClosedIcon />
                        ) : (
                            <EyeOpenedIcon />
                        )}
                    </button>
                }
            </div>
            {error && error.trim().length > 0 && (
                <span className={s.error}>{error}</span>
            )}
        </div>
    );
};
