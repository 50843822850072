import { useEffect } from 'react';
import { isApiResponse } from '@/shared/lib/utils/typeChecker';
import { useAlert } from '@/shared/lib/hooks/useAlert';
import { codeList, CodeListI } from '@/shared/model/types/error';

export const useError = (error: any) => {
    const onAlert = useAlert();
    useEffect(() => {
        if (error)
            if (isApiResponse(error)) {
                onAlert(
                    codeList[error.data.details.code as keyof CodeListI],
                    true,
                );
            } else onAlert('Произошла неизвестная ошибка', true);
    }, [error]);
};
