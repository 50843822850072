import useDebounce from '@/shared/lib/hooks/useDebounce';
import { Input, InputProps } from '@/shared/ui/Input/Input';
import { useCallback, useEffect, useState, KeyboardEvent } from 'react';
import Search from '@/shared/assets/icons/search.svg';
import s from './RegionSearch.module.scss';
import { useOutsideClick } from '@/shared/lib/hooks/useOutsideClick';
import { Loader } from '@/shared/ui/Loader/Loader';
import { useLazyGetRegionsQuery } from '@/features/RegionSearch';

interface RegionSearchProps extends InputProps {
    validate: (value: string) => void;
}

export const RegionSearch = (props: RegionSearchProps) => {
    const { validate, value, onChange, isSubmitted, error, ...rest } = props;
    const [trigger, { data, isFetching }] = useLazyGetRegionsQuery();
    const [open, setOpen] = useState(false);
    const debounceValue = useDebounce(value, 500);

    const onClose = useCallback(() => {
        setOpen(false);
        validate(value);
    }, [value, setOpen]);

    const ref = useOutsideClick(open, onClose);

    const onKeyDown = useCallback(
        (e: KeyboardEvent) => {
            if (e.key === 'Escape') {
                e.stopPropagation();
                onClose();
            }
        },
        [onClose],
    );

    useEffect(() => {
        if (debounceValue) {
            trigger(debounceValue);
            setOpen(true);
        } else {
            setOpen(false);
        }
    }, [debounceValue]);

    const regionFormat = (region: string) => {
        if (region) return region.split('_')[1];
    };

    const onSelect = (item: string) => {
        onChange(regionFormat(item));
        setOpen(false);
    };
    const onSelectAnotherRegion = () => {
        onChange('-');
        setOpen(false);
    };

    return (
        <div
            className={s.RegionSearch}
            ref={ref}
            onKeyDown={open ? onKeyDown : () => {}}
        >
            <Input
                value={value}
                onChange={onChange}
                label={'Регион регистрации*'}
                placeholder={'Введите регион'}
                error={error}
                isSubmitted={isSubmitted}
                icon={
                    isFetching ? (
                        <div className={s.loader}>
                            <Loader />
                        </div>
                    ) : (
                        <Search className={s.icon} />
                    )
                }
                onFocus={() => setOpen(true)}
                {...rest}
            />
            {open &&
                debounceValue &&
                !isFetching &&
                regionFormat(data?.regions[0]) !== debounceValue &&
                debounceValue !== '-' && (
                    <div className={s.dropdown}>
                        <div className={s.wrapper}>
                            <>
                                <button
                                    type={'button'}
                                    className={s.item}
                                    onClick={onSelectAnotherRegion}
                                >
                                    Другой регион
                                </button>
                                {data?.regions?.map((item) => {
                                    if (debounceValue !== regionFormat(item))
                                        return (
                                            <button
                                                key={item}
                                                type={'button'}
                                                className={s.item}
                                                onClick={() => onSelect(item)}
                                            >
                                                {regionFormat(item)}
                                            </button>
                                        );
                                })}
                            </>
                        </div>
                    </div>
                )}
        </div>
    );
};
